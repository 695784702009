.custom_tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 100%;
}

.custom_tooltip.hide{

}

.custom_tooltip .tooltiptext {
  visibility: hidden;
  width: max-content;
  font-size: 13px;
  color: #282828;
  text-align: center;
  border-radius: 5px;
  padding: 5px 10px;
  position: absolute;
  color: #fff;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: all 0.3s;
}

.custom_tooltip .tooltiptext.right {
  bottom: auto;
  top: 50%;
  transform: translateY(-50%);
  left: 125%;
}
.custom_tooltip .tooltiptext.left {
  bottom: auto;
  top: 50%;
  transform: translateY(-50%);
  left: auto;
  right: 125%;
}
.custom_tooltip .tooltiptext.bottom {
  bottom: -125%;
}

.custom_tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.custom_tooltip .tooltiptext .tooltip_arrow {
  position: absolute;
  top: 100%;
  left: 50%;
  border-width: 8px;
  border-style: solid;
  transform: translateX(-50%);
}

.custom_tooltip .tooltiptext .tooltip_arrow.right {
  top: 50%;
  transform: translateY(-50%);
  left: auto;
  right: 100%;
}

.custom_tooltip .tooltiptext .tooltip_arrow.left {
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  right: auto;
}
.custom_tooltip .tooltiptext .tooltip_arrow.bottom {
 bottom: 100%;
 top: auto;
  left: 50%;
}
