
.time_line_content{
  padding-right: 40px;
}

.timeline {
  margin-top: 20px;
  position: relative;
  
}

.timeline:before {
  position: absolute;
  content: "";
  width: 4px;
  height: calc(100% + 50px);
  background-color: var(--vio-1);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8a9196', endColorstr='#62696d',GradientType=1 );
  right: -14px;
  top: 5px;
  border-radius: 4px;
}

.timeline-month {
  position: relative;
  padding: 4px 15px 4px 35px;
  background-color: #FFF;
  display: inline-block;
  width: auto;
  z-index: 3;
  border-radius: 40px;
  border: 1px solid var(--vio-1);
  box-shadow: 0 10px 15px -10px #767676;
  margin-bottom: 30px;
}


.timeline-month:before {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  background-color: var(--vio-1);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8a9196', endColorstr='#70787d',GradientType=0 );
  border-radius: 100%;
  border: 1px solid #FFF;
  left: 5px;
  top: 5.5px;
}

.timeline-section {
  padding-left: 35px;
  display: block;
  position: relative;
  margin-bottom: 30px;
}

.timeline-date {
  margin-bottom: 15px;
  padding: 2px 15px;
  background: var(--vio-1);
  color: #FFF;
  position: relative;
  display: inline-block;
  border-radius: 20px;
  border: 1px solid #FFF;
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}
.timeline-section:before {
  content: "";
  position: absolute;
  width: 30px;
  height: 2px;
  background-color: var(--vio-1);
  top: 14px;
  right: -7px;
}

.timeline-section:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: var(--vio-1);
  top: 10px;
  right: -17px;
  border: 1px solid #FFF;
  border-radius: 100%;
}

.timeline-section .col-sm-4 {
  margin-bottom: 15px;
}

.timeline-box {
  position: relative;
  height: 100%;
  background-color: #FFF;
  border-radius: 15px;
box-shadow: 10px 10px 45px -18px rgba(81,81,81,0.89);
-webkit-box-shadow: 10px 10px 45px -18px rgba(81,81,81,0.89);
-moz-box-shadow: 10px 10px 45px -18px rgba(81,81,81,0.89);  border: 1px solid #FFF;
  transition: all 0.3s ease;
  overflow: hidden;
}

.box-icon {
  position: absolute;
  right: 5px;
  top: 0px;
}

.box-title {
  padding: 5px 15px;
  border-bottom: 1px solid var(--vio-1);
  font-weight: bolder;
  color: var(--vio-1);
}

.box-title i {
  margin-right: 5px;
}

.box-content {
  padding: 5px 15px;
  background-color: #FFF;
  padding: 20px;
}

.box-content strong {
  color: #666;
  font-style: italic;
  margin-right: 5px;
}

.box-item {
  margin-bottom: 5px;
}

.box-footer {
  padding: 5px 15px;
  border-top: 1px solid #FFF;
  background-color: #FFF;
  text-align: right;
  font-style: italic;
}


  
/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
  /* Your styles here */
  .time_line_content{
    padding-right: 20px;
  }
  
  .timeline-section {
    padding-left: 0;
    display: block;
    position: relative;
    margin-bottom: 30px;
  }
}

/* Small devices (tablets, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  /* Your styles here */
  .time_line_content{
    padding-right: 70px;
  }
  
  .timeline-section {
    padding-left: 0;
    display: block;
    position: relative;
    margin-bottom: 30px;
  }
  

}

/* Medium devices (desktops, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  /* Your styles here */
  .time_line_content{
    padding-right: 70px;
  }
  
  .timeline-section {
    padding-left: 0;
    display: block;
    position: relative;
    margin-bottom: 30px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  /* Your styles here */
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
  /* Your styles here */
}

/* Extra extra large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  /* Your styles here */
}
