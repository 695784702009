.branch_card {
  position: relative;
  display: flex;
  /* min-height:  150px; */
  justify-content: space-between;
  height: 100%;
  transition: 0.2s ease;
  flex-direction: column;
  line-height: 1.6;
  transition: all 0.64s cubic-bezier(0.23, 1, 0.32, 1);
  cursor: pointer;
  border-radius: 12px;
  background: rgb(255,255,255);
background: linear-gradient(343deg, rgba(255,255,255,0.8492647058823529) 0%, rgba(255,255,255,0.12097338935574231) 100%);
border: 1px solid rgb(186, 186, 186);
}



.branch_tilt:hover{
  scale: 1.1;
}

.branch_card .branch_card_content{
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  text-align: center;
  align-items: center;
  /* justify-content: space-between !important; */
  justify-content: center;
  font-size: 16px !important;
  border-radius: 24px;
  background: transparent;
  color: #222;
  border-radius: 12px !important;
  z-index: 1;
  transition: all 0.64s cubic-bezier(0.23, 1, 0.32, 1);
  
}

.branch_card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(26, 26, 26, 0.327);
  border-radius: inherit;
  height: 100%;
  width: 100%;
  opacity: 0;

  transform: skew(-24deg);
  clip-path: circle(0% at 50% 50%);
  transition: all 0.64s cubic-bezier(0.23, 1, 0.32, 1);
  
}

.branch_card::before {
  opacity: 1;
  background-image: radial-gradient(
      circle at 39% 47%,
      rgba(107, 107, 107, 0.08) 0%,
      rgba(107, 107, 107, 0.08) 33.333%,
      rgba(72, 72, 72, 0.08) 33.333%,
      rgba(72, 72, 72, 0.08) 66.666%,
      rgba(36, 36, 36, 0.08) 66.666%,
      rgba(36, 36, 36, 0.08) 99.999%
    ),
    radial-gradient(
      circle at 53% 74%,
      rgba(182, 182, 182, 0.08) 0%,
      rgba(182, 182, 182, 0.08) 33.333%,
      rgba(202, 202, 202, 0.08) 33.333%,
      rgba(202, 202, 202, 0.08) 66.666%,
      rgba(221, 221, 221, 0.08) 66.666%,
      rgba(221, 221, 221, 0.08) 99.999%
    ),
    radial-gradient(
      circle at 14% 98%,
      rgba(184, 184, 184, 0.08) 0%,
      rgba(184, 184, 184, 0.08) 33.333%,
      rgba(96, 96, 96, 0.08) 33.333%,
      rgba(96, 96, 96, 0.08) 66.666%,
      rgba(7, 7, 7, 0.08) 66.666%,
      rgba(7, 7, 7, 0.08) 99.999%
    ),
    linear-gradient(45deg, rgb(97, 14, 117), rgb(20, 32, 127));
  transform: skew(0deg);
  clip-path: circle(140.9% at 0 0);
}
.branch_card .branch_card_content {
  color: #ffffff;
  font-size: 23px;
}
.branch_card_content_location{
  display: flex;
  gap: 10px;
  padding: 10px;
  align-items: center;
  /* justify-content: space-between !important; */
  justify-content: center;
  font-size: 17px !important;
  border-radius: 24px;
  background: transparent;
  color: #222;
  border-radius: 12px !important;
  z-index: 1;
  transition: all 0.64s cubic-bezier(0.23, 1, 0.32, 1);

}