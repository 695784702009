

.generations_btns{
  flex-wrap: wrap;
}  















