
.best_std_card{
  background-color: #FFF;
  padding: 20px;
  border-radius: 15px !important;
  transition: .2s ease;
  overflow: hidden;
  width: 400px;
  
}

.best_std_card .std_card_content{
  font-size: 5em;
  font-weight: bold;
  padding: 0;
  color: #fff;
  /* margin: 0 0 0.25em 0; */
  line-height: 0.75;
}

.best_std_card .pricing-deco-img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 160px;
}


.best_std_card .std_image{
  width: 100%;
  position: relative;
  height: 300px;
  border-radius: 20px 20px 0 0 ;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.best_std_card .std_image img{
  width:  100%;
  height: 100%;
  user-select: none;
  -webkit-user-drag: none;
  border-radius: 20px !important;  
  overflow: hidden;
  object-fit: contain;

}


.best_std_card .medal_icon{
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 35px;
  color:gold;
  filter: drop-shadow(0 0px 22px gold);
}

.best_std_card:hover .deco-layer--1 {
  -webkit-transform: translate3d(15px, 0, 0);
  transform: translate3d(15px, 0, 0);
}

.best_std_card:hover .deco-layer--2 {
  -webkit-transform: translate3d(-15px, 0, 0);
  transform: translate3d(-15px, 0, 0);
}

 .deco-layer {
  transition: transform 0.3s;
}

.best_std_card .std_info{
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: end;
  color: #FFF;
  
}

.best_std_card .std_info h4{
  font-weight:  600;
  font-size: 30px;
}


  
/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
  /* Your styles here */
  .best_std_card{
    padding: 20px;

    width: 100%;
    
  }
  
}

/* Small devices (tablets, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  /* Your styles here */
  

}

/* Medium devices (desktops, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  /* Your styles here */

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  /* Your styles here */
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
  /* Your styles here */
}

/* Extra extra large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  /* Your styles here */
}
