ul {
  list-style: none;
}

.example-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  background-color: #FFF;
  padding: 10px;
  padding-inline: 60px;
  border-radius: 20px 20px 0 0 ;
  border-width: 3px;
  border-left: none;
  border-top: none;
  border-right: none;
}

.example-2 .icon-content {
  margin: 0 10px;
  position: relative;
}
.example-2 .icon-content .tooltip {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  padding: 6px 10px;
  border-radius: 15px;
  opacity: 0;
  visibility: hidden;
  font-size: 14px;
  transition: all 0.3s ease;
}
.example-2 .icon-content:hover .tooltip {
  opacity: 1;
  visibility: visible;
  top: -50px;
}
.example-2 .icon-content a {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 20%;
  color: #4d4d4d;
  background-color: transparent;
  transition: all 0.3s ease-in-out;
}
.example-2 .icon-content a:hover {
  box-shadow: 3px 2px 45px 0px rgb(0 0 0 / 50%);
}
.example-2 .icon-content a svg {
  position: relative;
  z-index: 1;
  width: 30px;
  height: 30px;
}
.example-2 .icon-content a:hover {
  color: white !important;
  cursor: pointer;
}
.example-2 .icon-content a .filled {
  position: absolute;
  top: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: #000;
  transition: all 0.3s ease-in-out;
}
.example-2 .icon-content a:hover .filled {
  height: 100%;
}
.example-2 .icon-content a[data-social="facebook"] .filled,
.example-2 .icon-content a[data-social="facebook"] ~ .tooltip {
  background-color: #1877F2;
}
.example-2 .icon-content a[data-social="instagram"] .filled,
.example-2 .icon-content a[data-social="instagram"] ~ .tooltip {
  background: linear-gradient(
    to right,
    #ad6fd6,#ed6868,#fcb045
  );}
.example-2 .icon-content a[data-social="x"] .filled,
.example-2 .icon-content a[data-social="x"] ~ .tooltip {
  background-color: #242323;
}
.example-2 .icon-content a[data-social="youtube"] .filled,
.example-2 .icon-content a[data-social="youtube"] ~ .tooltip {
  background-color: #c90822;
}
.example-2 .icon-content a[data-social="udemy"] .filled,
.example-2 .icon-content a[data-social="udemy"] ~ .tooltip {
  background-color: #A435F0;
}
.example-2 .icon-content a[data-social="tiktok"] .filled,
.example-2 .icon-content a[data-social="tiktok"] ~ .tooltip {
  background-color: #222;
}
  
/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
  /* Your styles here */
  .example-2 {
 
    padding-inline: 10px;
  }
  .example-2 .icon-content {
    margin: 0 5px;
  }
}

/* Small devices (tablets, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  /* Your styles here */
  
  .example-2 {
  
    padding-inline: 20px;
  }
  .example-2 .icon-content {
    margin: 0 5px;
  }
  
}

/* Medium devices (desktops, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  /* Your styles here */
  .example-2 {
  
    padding-inline: 30px;
  }

  .example-2 .icon-content {
    margin: 0 5px;
  }

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  /* Your styles here */
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
  /* Your styles here */
}

/* Extra extra large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  /* Your styles here */
}
