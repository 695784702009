

.home_banner{
  min-height: calc(100vh - var(--wave-height));
  display: flex;
  justify-content: center;
  padding-block: 20px;
}


.home_banner  img{
  width: 100%

}

.home_banner .banner_content{
  padding: 40px 20px 0 0 ;
}


  
/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
  /* Your styles here */
  .home_banner  img{
    display: none;
  
  }
  .home_banner .banner_content{
    padding: 20px;
  }
  
}

/* Small devices (tablets, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  /* Your styles here */
  
  .home_banner  img{
    display: none;
  
  }
  .home_banner .banner_content{
    padding: 20px;
  }

}

/* Medium devices (desktops, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  /* Your styles here */

  .home_banner .banner_content{
    padding: 40px 20px 0 0 ;
  }
  
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  /* Your styles here */
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
  /* Your styles here */
}

/* Extra extra large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  /* Your styles here */
}
