.splash_screen{
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  
}




.splash_screen .splash_logo{
  scale: 4;
}

.scale-up-center {
	-webkit-animation: scale-up-center 1s ease-in-out infinite alternate backwards;
	        animation: scale-up-center 1s ease-in-out infinite alternate backwards;
}

 @-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
