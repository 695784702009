


.teacher_card{
  background-color: #b9b9b9;
  border-radius: 12px;
  padding: 20px;
  position: relative;
  cursor: pointer;
  display: flex;
  z-index: 1  ;
  width: 100%;
  gap: 20px;
  background: rgb(232,232,232);
background: linear-gradient(90deg, rgba(232,232,232,1) 0%, rgba(251,218,255,1) 52%, rgba(218,218,218,1) 98%);
}



.teacher_card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(26, 26, 26, 0.327);
  border-radius: inherit;
  height: 100%;
  width: 100%;
  opacity: 0;
z-index: 0;
  clip-path: circle(0% at 50% 50%);
  transition: all 0.64s cubic-bezier(0.23, 1, 0.32, 1);
}



.teacher_card:hover::before {
  opacity: 1;
  background-image: radial-gradient(
      circle at 39% 47%,
      rgba(107, 107, 107, 0.08) 0%,
      rgba(107, 107, 107, 0.08) 33.333%,
      rgba(72, 72, 72, 0.08) 33.333%,
      rgba(72, 72, 72, 0.08) 66.666%,
      rgba(36, 36, 36, 0.08) 66.666%,
      rgba(36, 36, 36, 0.08) 99.999%
    ),
    radial-gradient(
      circle at 53% 74%,
      rgba(182, 182, 182, 0.08) 0%,
      rgba(182, 182, 182, 0.08) 33.333%,
      rgba(202, 202, 202, 0.08) 33.333%,
      rgba(202, 202, 202, 0.08) 66.666%,
      rgba(221, 221, 221, 0.08) 66.666%,
      rgba(221, 221, 221, 0.08) 99.999%
    ),
    radial-gradient(
      circle at 14% 98%,
      rgba(184, 184, 184, 0.08) 0%,
      rgba(184, 184, 184, 0.08) 33.333%,
      rgba(96, 96, 96, 0.08) 33.333%,
      rgba(96, 96, 96, 0.08) 66.666%,
      rgba(7, 7, 7, 0.08) 66.666%,
      rgba(7, 7, 7, 0.08) 99.999%
    ),
    linear-gradient(45deg, rgb(97, 14, 117), rgb(20, 32, 127));
  transform: skew(0deg);
  clip-path: circle(140.9% at 0 0);
}


.teacher_card .teacher_card_img{
  border-radius: 12px;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  /* z-index: 1; */
   width: 150px;
  height: 150px; 
  background-color: #FFF;
}

.teacher_card  img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.teacher_card .teacher_card_socilas{
  display: flex;
  align-items: center;
  gap: 10px;
}

.teacher_card_content{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}

.teacher_card_content h3{
  font-weight: bolder;
  color: #222;
  font-size: 20px;
  
  transition: .2s ease;
  
}
.teacher_card_content h5{
  font-weight: bolder;
  color: #222;
  font-size: 17px;
  margin-top: 10px;
  transition: .2s ease;
}


.teacher_card:hover .teacher_card_content h3 {
  color: #FFF;
}
.teacher_card:hover .teacher_card_content  h5 {
  color: #FFF;
}

  
/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
  /* Your styles here */
  .teacher_card .teacher_card_img{
    width: 170px;
    height: 170px;
  }
  

  .teacher_card{
    flex-direction: column;
    align-items: center ;
  };
}

/* Small devices (tablets, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  /* Your styles here */
  

}

/* Medium devices (desktops, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  /* Your styles here */

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  /* Your styles here */
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
  /* Your styles here */
}

/* Extra extra large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  /* Your styles here */
}
