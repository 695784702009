.footer1_container {
  width: 100%;
  padding: 50px;
  padding-bottom: 20px;
}

.footer1_topWave {
  background-color: #fff;
}

.footer1_container .footer1_content {
  padding: 0 50px;
}

.footer1_container .footer1_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer1_container .footer1_header .footer1_logo {
  min-width: 200px;
  max-width: 200px;
}

.footer1_container .footer1_header .svg_logo path {
  fill: #fff !important;
}

.footer1_container .footer1_header .footer1_logo img {
  width: 100%;
}

.footer1_container .footer1_header .socials {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: end;
}

.footer1_container .footer1_header .socials .icon {
  font-size: 30px;
  color: #d1d1d1;
}

.footer1_columns {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

.footer1_columns .column {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.footer1_columns .column .col_item {
  font-size: 20px;
  color: #d1d1d1;
  font-weight: bolder;
  cursor: pointer;
  transition: 0.3s ease;
}
.footer1_columns .column .col_item:hover {
  font-size: 23px;
}

.footer1_copyRight {
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-top: 1px solid #d1d1d1;
  color: #d1d1d1;
  font-size: 15px;
}

  
/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
  .footer1_container {
    width: 100%;
    padding: 15px;
    padding-bottom: 20px;
  }
  
  /* Your styles here */
  .footer1_container .footer1_header {
    margin-inline-end: 20px ;
    justify-content: space-between;
  }


  .footer1_columns {
    display: flex;
    flex-direction: column;
   row-gap: 20px; 
  }

  .footer1_container .footer1_content {
    padding: 0 20px;
  } 
  .footer1_columns .column {
    width: 100%;
  }

  
  .footer1_container .footer1_header .footer1_logo {
      min-width: 100px;
      max-width: 100px;
  }
  .footer1_container .footer1_header .socials {
    width: 100%;
  }
}

/* Small devices (tablets, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  /* Your styles here */
  

}

/* Medium devices (desktops, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  /* Your styles here */

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  /* Your styles here */
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
  /* Your styles here */
}

/* Extra extra large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  /* Your styles here */
}
