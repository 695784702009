.pet_food_container{
  margin-top: 20px;
  padding-inline:50px ;
  width: 100%;
}

.pet_food_title{
  color: #F86408;
  font-size: 40px;
  text-align: center;
  font-weight: bolder;
  margin-bottom: 25px;
  position: relative;
}

.pet_food_title .arrow{
  position: relative;
}

.pet_food_title .arrow .pf_looped_arrow{
  position: absolute;
  top: 60px;
  left:0;
}


.pf_looped_arrow{
  left: calc(50%);
  z-index: 100;

}

.pf_looped_arrow svg{
  transform: rotate(94deg);
  width: 100px;
  color: #64cee6ec;

}

.b-border{
  color: #222 !important;
  position: relative;
}

 .b-border span{
    bottom: -20px;
    display: block;
    height: auto;
    position: absolute;
    width: 100%;
    content: '';
    display: block;
    width: 100%;
    height: 10px; /* Adjust the height as needed */
    background-size: cover; /* Ensure the image covers the whole area */
    position: absolute;
    bottom: -15px;
    left: 0;
}


.swiper-3d ,
.swiper-slide-shadow-left,
.swiper-slide-shadow-right{
  background-image: none !important;
}


.swiper-slide {
  width: 200px;
  background-color: transparent;
  border-radius: 10px;
  background-size: cover;
  /* border: 1px solid white; */
  text-align: center;
  /* background-image: url(./assets/slideBG.png); */
  color: #ffffff;
}
.swiper-slide.active{
  z-index: 1000;
}


.pet_food_swiper{
  width:100%;
  margin-inline: auto;
  position: relative;
}

.food_card{
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  /* transform: rotate(20deg); */
  transform-origin: center;
  transition: .3s ease;

}

.food_card.lesThanActive{
  transform: rotate(-20deg) scale(0.8);
  opacity: .5;
  
  
}
.food_card.moreThanActive{
  opacity: .5;
transform: rotate(20deg) scale(0.8);

}

.food_card .food_slide{
  /* width:300px; */
  padding-inline: 40px,

}

.food_card .prodName{
  font-size: 20px;
  color: #222 !important;
  font-weight: bolder;
}

.food_slide{
  width: 100%;
}

.food_slide img{
    width: 100%;
}


.Pet_food_bttom{
  margin-top: 20px;
}

.Pet_food_bttom .feats{
  display: flex;
  margin-top: 50px;
  align-items: center;
  justify-content: space-between;
  padding-inline: 100px;

}

.Pet_food_bttom .feats .feat_item{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.Pet_food_bttom .feats .feat_item .featIcon{
  width: 80px;
  height: 80px;
  /* background-image: url("../../../assets//images/trueCircule.png"); */
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  font-size: 30px;
  color: #292929;
  align-items: center;
  justify-content: center;
}

.Pet_food_bttom .feats .feat_item .feat_title{
  color: #292929;
  font-size: 15px;
  font-weight: bolder;
}


.Pet_food_bttom .feats .feat_item .feat_info{
  color: #2e2e2e;
  font-size: 13px;
  width:130px ;
  text-align: center;
}




.pet_food .custom-prev {
  position: absolute;
  background-color: #222;
  top: 50%;
  left: calc(50% - 192px);
  color: white;
  border: none;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  width: 40px;
  height: 40px;
}


  .pet_food .custom-next{
    right: calc(50% - 192px);
    left: auto;
    width: 40px;
  height: 40px;
  }



/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
.pet_food_title{
  font-size: calc(10px + 1.1vw);
}
.Pet_food_bttom .feats{
  flex-wrap: wrap;
  padding-inline: 10px;
gap: 20px;
}
.pet_food_swiper{
  width: 100%;
}
.pet_food_container{
  margin-top: 10px;
  padding-inline:5px ;
}
.Pet_food_bttom .feats .feat_item .featIcon{
  width: 40px;
  height: 40px;
  font-size: 25px;

}

.Pet_food_bttom .feats .feat_item .feat_title{
  font-size: 10px;

}

.Pet_food_bttom .feats .feat_item .feat_info{
  width:80px ;
  font-size: 9px;

}

.food_card .prodName{
  font-size: 15px;
  color: #222 !important;
  font-weight: bolder;
}

.food_card .food_slide{
  /* width:300px; */
  padding-inline: 10px,

}




.pet_food .custom-prev {

  left: 0 !important;

}


  .pet_food .custom-next{
    right: 0 !important;
    
  }

  .pf_looped_arrow svg{
    transform: rotate(94deg);
    width: 60px;
    color: #64cee6ec;
  
  }

  .pet_food_title .arrow .pf_looped_arrow{
    top: 10px;
  }

}
    
    /* Medium devices (tablets, less than 992px) */
    @media (min-width: 768px) and (max-width: 991.98px) {
      .pet_food_title{
        font-size: calc(10px + 1.4vw);
      }
      .Pet_food_bttom .feats{
        flex-wrap: wrap;
        padding-inline: 30px;
      gap: 20px;
      }
      
      .pet_food_container{
        margin-top: 10px;
        padding-inline:5px ;
      }
      .Pet_food_bttom .feats .feat_item .featIcon{
        width: 50px;
        height: 50px;
        font-size: 25px;
      
      }
      
      .Pet_food_bttom .feats .feat_item .feat_title{
        font-size: 15px;
      
      }
      
      .Pet_food_bttom .feats .feat_item .feat_info{
        width:85px ;
        font-size: 10px;
      
      }
    
    }
    
    /* Large devices (desktops, less than 1200px) */
    @media (min-width: 992px) and (max-width: 1199.98px) {
      .prev_container .layout{
        display: block;
      }
      .prev_container .image{
        width: 200px;
      }
      
  .prev_container .info{
    font-size: 20px;
    color:#FFF;
  }
  
  
  .prev_content{
    width: 100%;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  
  .bottom_txt{
    font-size: 15px;
    margin-top: 10px;
    color: #fff;
  }
  
  
    
    }
    
    /* Extra large devices (large desktops, less than 1400px) */
    @media (max-width: 1399.98px) {
    }
    
    /* Extra extra large devices (larger desktops, less than 1600px) */
    @media (max-width: 1599.98px) {
    }
