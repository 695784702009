


.custom-prev , .custom-next{
  position: absolute;
  background-color: #222;
  top: 50%;
  left: 0;
  color: white;
  border: none;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}


.custom-next{
  right: 0;
  left: auto;
}


.swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
  background: #ee711e8b;
  bottom: -30px !important;
}

.swiper-pagination-bullet-active {
  color: #fff;
  background: #EE701E;
}

.mobile_swiper .swiper-pagination {
  bottom: auto; /* Remove default bottom positioning */
  bottom: 0px; /* Position at the top, adjust as needed */
  left: 0; /* Adjust left/right positioning if needed */
  right: 0;
  width: 100%; /* Ensure the pagination spans the width of the container */
  text-align: center; /* Center align the pagination bullets */
  z-index: 4px !important;
}

.mobile_swiper .step .image{
  margin-block: 30px;
}



.swiper-3d ,
.swiper-slide-shadow-left,
.swiper-slide-shadow-right{
  background-image: none !important;
}


.swiper-slide {
  width: 200px;
  background-color: transparent;
  border-radius: 10px;
  background-size: cover;
  /* border: 1px solid white; */
  text-align: center;
  /* background-image: url(./assets/slideBG.png); */
  color: #ffffff;
}

.food_card{
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5px;
}

.food_card .food_slide{
  /* width:300px; */
}

.food_card .prodName{
  font-size: 20px;
  color: #222 !important;
  font-weight: bolder;
}

.food_slide{
  width: 100%;
}

.food_slide img{
    width: 100%;
}
