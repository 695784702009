.vid_card {
  display: flex;
  background-color: var(--white);
  border-radius: 10px;
  flex-direction: column;
  background-color: #FFF;
  overflow: hidden;
  transition: all 0.3s;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  padding: 10px;
}

.vid_card:hover {
  transform: translateY(-10px);
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1),
  -4px -4px 12px rgba(0, 0, 0, 0.08);
}

.vid_card .vid-card-image{
  width: 100%;
  height: 200px;
}

.vid_card .vid-card-image img{
  border-radius: 12px;
  width: 100%;
    object-position: 0% 5%;

  overflow: hidden;
  height: 100%;
  object-fit: cover;
}

.vid_card .vid_card_content{
  padding: 10px;
  font-size: 15px;
  font-weight: 600;
  color:#373737
}

.vid_card .vid_card_overlay{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  opacity: 0;
  left: 0;
  bottom: 0;
  transition: .2s ease;
  background-color: #00000032;
}

.vid_card .vid_card_overlay:hover{
  opacity: 1;
}