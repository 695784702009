


.home_logos_section{
  display: grid;
  grid-template-columns: repeat(5 , 1fr);
  justify-content: space-between;
  align-content: center;
  padding-block: 40px ;
  border-radius: 12px !important;
overflow: hidden;
background: rgb(232,232,232);
background: linear-gradient(90deg, rgba(232,232,232,1) 0%, rgba(251,218,255,1) 52%, rgba(218,218,218,1) 98%);

}

  
/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
  /* Your styles here */
  .home_logos_section{
    grid-template-columns: repeat(1 , 1fr);
    padding-block: 40px ;
    border-radius: 15px !important;
    row-gap: 50px;
    margin-inline: 40px;
  }
  
}

/* Small devices (tablets, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  /* Your styles here */
  .home_logos_section{
    grid-template-columns: repeat(2 , 1fr);
    padding-block: 40px ;
    border-radius: 15px !important;
    row-gap: 50px;
    margin-inline: 40px;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  
  
}

/* Medium devices (desktops, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .home_logos_section{
    grid-template-columns: repeat(3 , 1fr);
    padding-block: 40px ;
    border-radius: 15px !important;
    row-gap: 50px;
    margin-inline: 40px;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  /* Your styles here */

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  /* Your styles here */
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
  /* Your styles here */
}

/* Extra extra large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  /* Your styles here */
}
