.teacher_bg_banner{
  width: 100%;
  height: 300px;
  position: relative;
  
}

.teacher_bg_banner img{
  width: 100%;
  height: 100%;
  object-fit: fill;
  /* object-position: 0% 20%; */
}

.teacher_bg_banner_overlay{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.34506302521008403) 48%, rgba(255,255,255,0) 100%);  transition: .2s ease;
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}


.banner_modal{
  height: 400px !important;
  object-fit: contain !important;

}

.closebtn{
  background-color: #FFF; 
  padding: 10px;
  border-radius: 50px;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  top: -10px;
  cursor: pointer;
  transition: .3s ease;
}
.closebtn:hover{
  
}


  
/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
  /* Your styles here */
  .teacher_bg_banner{
    width: 100%;
    height: 150px;
    position: relative;
    
  }
}

/* Small devices (tablets, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  /* Your styles here */
  .teacher_bg_banner{
    width: 100%;
    height: 200px;
    position: relative;
    
  }

}

/* Medium devices (desktops, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  /* Your styles here */
  .teacher_bg_banner{
    width: 100%;
    height: 200px;
    position: relative;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  /* Your styles here */
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
  /* Your styles here */
}

/* Extra extra large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  /* Your styles here */
}
