.teacherBackground{
  position: relative;
}

.teacherBackground .teacherBackground_teacher_image{
  position: absolute;
  bottom: 0;
  right: 50%;
  transform: translate(50% , 50%) !important;
}


.teacher_modal_image{
  width: 450px;
  padding: 20px;
  object-fit: contain;
  height: 450px;
}


  
/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
  /* Your styles here */
  .teacherBackground .teacherBackground_teacher_image{
    position: absolute;
    bottom: 0;
    right: 50%;
    transform: translate(50% , 50%) !important;
  }
  .teacher_modal_image{
    width: 350px;
    height: 350px;
  }
  
}

/* Small devices (tablets, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  /* Your styles here */
  

}

/* Medium devices (desktops, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  /* Your styles here */

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  /* Your styles here */
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
  /* Your styles here */
}

/* Extra extra large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  /* Your styles here */
}
