.event_card_container{
  transition: .2s ease;
  border-radius: 20px;
}


.event_card_container:hover{
  scale: 1.03;
}
.event_card_container.inmodal:hover{
  scale: 1;
}

.event_card{
  padding: 20px;
  padding-inline: 50px;
  background: rgb(232,232,232);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(90deg, rgba(232,232,232,1) 0%, rgba(251,218,255,1) 52%, rgba(218,218,218,1) 98%);
  position:relative;
  overflow: visible;
  border-radius: 20px;

}

.event_card .event_timer{
  position: absolute;
  bottom: -20px;

  padding:3px 20px;
  background-color: var(--vio-1);
  border-radius: 10px;
  color: #FFF;
  border: 4px solid #FFF;
  font-size: 15px;
}


.event_card .event_card_content{
  display: flex;
  flex-direction: column;
  gap:10px 
}

.event_card .event_card_content .event_card_date{
  color: #3a3a3a;
  font-weight: bolder;
}

.event_card .event_card_content .event_card_title{
  font-size: 30px;
  color: var(--vio-1);
}


  
/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
  /* Your styles here */
  .event_card{
    padding: 20px;
    padding-inline: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .event_card h5{
    font-size: 15px;
  }
  .event_card .event_card_content .event_card_title{
    font-size: 20px;
    margin-block: 10px;
    text-align: center;

  }
  
}

/* Small devices (tablets, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  /* Your styles here */
  
  .event_card{
    padding: 20px;
    padding-inline: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .event_card h5{
    font-size: 15px;
  }
  .event_card .event_card_content .event_card_title{
    font-size: 25px;
    margin-block: 10px;
    text-align: center;
  }
  
}

/* Medium devices (desktops, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  /* Your styles here */

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  /* Your styles here */
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
  /* Your styles here */
}

/* Extra extra large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  /* Your styles here */
}
