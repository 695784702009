/* From Uiverse.io by gharsh11032000 */
.subjectCard {
  position: relative;
  display: flex;
  width: 100%;
  min-width: 360px;
  transition: 0.2s ease;
  line-height: 1.6;
  transition: all 0.64s cubic-bezier(0.23, 1, 0.32, 1);
  cursor: pointer;
  border-radius: 12px;
  background: rgb(232, 232, 232);
  background: linear-gradient(
    90deg,
    rgba(232, 232, 232, 1) 0%,
    rgba(251, 218, 255, 1) 52%,
    rgba(218, 218, 218, 1) 98%
  );
  direction: rtl;
}

.subjectCard .content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  border-radius: 24px;
  background: transparent;
  color: #222;
  border-radius: 12px !important  ;
  z-index: 1;
  transition: all 0.64s cubic-bezier(0.23, 1, 0.32, 1);
}

.subjectCard::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(26, 26, 26, 0.327);
  border-radius: inherit;
  height: 100%;
  width: 100%;
  opacity: 0;

  transform: skew(-24deg);
  clip-path: circle(0% at 50% 50%);
  transition: all 0.64s cubic-bezier(0.23, 1, 0.32, 1);
}
.content .heading {
  font-weight: 700;
  font-size: 36px;
  line-height: 1.3;
  z-index: 1;
}

.subjectCard:hover::before {
  opacity: 1;
  background-image: radial-gradient(
      circle at 39% 47%,
      rgba(107, 107, 107, 0.08) 0%,
      rgba(107, 107, 107, 0.08) 33.333%,
      rgba(72, 72, 72, 0.08) 33.333%,
      rgba(72, 72, 72, 0.08) 66.666%,
      rgba(36, 36, 36, 0.08) 66.666%,
      rgba(36, 36, 36, 0.08) 99.999%
    ),
    radial-gradient(
      circle at 53% 74%,
      rgba(182, 182, 182, 0.08) 0%,
      rgba(182, 182, 182, 0.08) 33.333%,
      rgba(202, 202, 202, 0.08) 33.333%,
      rgba(202, 202, 202, 0.08) 66.666%,
      rgba(221, 221, 221, 0.08) 66.666%,
      rgba(221, 221, 221, 0.08) 99.999%
    ),
    radial-gradient(
      circle at 14% 98%,
      rgba(184, 184, 184, 0.08) 0%,
      rgba(184, 184, 184, 0.08) 33.333%,
      rgba(96, 96, 96, 0.08) 33.333%,
      rgba(96, 96, 96, 0.08) 66.666%,
      rgba(7, 7, 7, 0.08) 66.666%,
      rgba(7, 7, 7, 0.08) 99.999%
    ),
    linear-gradient(45deg, rgb(97, 14, 117), rgb(20, 32, 127));
  transform: skew(0deg);
  clip-path: circle(140.9% at 0 0);
}
.subjectCard:hover .content {
  color: #ffffff;
}
.subjectCard .subject_image {
  width: 40px;
  height: 40px;
}
.subjectCard .subject_image img {
  width: 100%;
  height: 100%;
}
.subjectCard:hover img {
  transition: 0.2s ease;
}

.subjectCard:hover img {
}

/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
  /* Your styles here */
  /* From Uiverse.io by gharsh11032000 */
  .subjectCard {
    position: relative;
    display: flex;
    min-width: 200px;
  }

  .subjectCard .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }
  
  .subjectCard_title {
    font-size: 20px;
  }
}

/* Small devices (tablets, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  /* Your styles here */
}

/* Medium devices (desktops, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  /* Your styles here */
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  /* Your styles here */
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
  /* Your styles here */
}

/* Extra extra large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  /* Your styles here */
}
