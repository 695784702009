
.show_more_btn {
  position: relative;
  margin: auto;
  padding: 12px 18px;
  transition: all 0.2s ease;
  border: none;
  text-wrap: nowrap;
  background: none;
  cursor: pointer;
}

.show_more_btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(10px, 5px);
  background: #dfceff;
  width: 60px;
  height: 45px;
  transition: all 0.3s ease;
}

.show_more_btn span {
  position: relative;
  font-family: "Ubuntu", sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: #6a32db;
}

.show_more_btn svg {
  position: relative;
  top: 0;
  margin-left: 10px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #6a32db;
  stroke-width: 2;
  transform: translateX(-5px);
  transition: all 0.3s ease;
}

.show_more_btn:hover:before {
  width: 100%;
  background: #dfceff;
}

.show_more_btn:hover svg {
  transform: translateX(0);
}

.show_more_btn:active {
  transform: scale(0.95);
}
