.teacher_img{
  width:220px ;
  height:220px ;
  position: relative;
}

.teacher_img img{
  width: 100%; 
  outline: 7px solid #FFF;
  height: 100%;
  background-color: #FFF;
  object-fit: cover;
  object-position: 0% 0%;
  border-radius: 20px;
}


.teacher_image_show_btn{
  position: absolute;
  right: 0;
  transform: translateX(50%);
  bottom: 0%;
}
  
/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
  /* Your styles here */
  .teacher_img{
    width:140px ;
    height:140px ;
  }
  .teacher_img img{
  outline: 4px solid #FFF;

  }
  
}

/* Small devices (tablets, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  /* Your styles here */
  .teacher_img{
    width:300px ;
    height:300px ;
  }
}

/* Medium devices (desktops, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  /* Your styles here */
  .teacher_img{
    width:200px ;
    height:200px ;
  }

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  /* Your styles here */
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
  /* Your styles here */
}

/* Extra extra large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  /* Your styles here */
}
