
.searchInput{
  padding: 5px 10px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  gap: 10px;
  border: 3px solid #dadada 
} 




.searchInput > input{
  border: none !important;
  outline: none !important;
background-color: transparent;
width: 100%;
padding: 3px;
}

