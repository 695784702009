
.teacher_section{
  position: relative;
}

.lottie_animation{
  position: absolute;
 top: 0;
 left: 0;
 z-index: -1;
 filter:saturate(1);
 opacity: .2;
 right: 0;
 bottom: 0;
 width: 100%;
 overflow: hidden;
 height: 100%;
}



.teacher_section_content{
  display: grid;
  grid-template-columns: repeat(3 , 1fr);
  gap: 20px;
  width: 100%;
}

  
/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
  /* Your styles here */
  .teacher_section_content{
    grid-template-columns: repeat(1 , 1fr);

  }
}

/* Small devices (tablets, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  /* Your styles here */
  .teacher_section_content{
    grid-template-columns: repeat(1 , 1fr);

  }

}

/* Medium devices (desktops, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  /* Your styles here */
  .teacher_section_content{
    grid-template-columns: repeat(1 , 1fr);

  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  /* Your styles here */
  .teacher_section_content{
    grid-template-columns: repeat(2 , 1fr);

  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
  /* Your styles here */
}

/* Extra extra large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  /* Your styles here */
}
