



.styled_background__{
  background-image: radial-gradient(
      circle at 39% 47%,
      rgba(107, 107, 107, 0.08) 0%,
      rgba(107, 107, 107, 0.08) 33.333%,
      rgba(72, 72, 72, 0.08) 33.333%,
      rgba(72, 72, 72, 0.08) 66.666%,
      rgba(36, 36, 36, 0.08) 66.666%,
      rgba(36, 36, 36, 0.08) 99.999%
    ),
    radial-gradient(
      circle at 53% 74%,
      rgba(182, 182, 182, 0.08) 0%,
      rgba(182, 182, 182, 0.08) 33.333%,
      rgba(202, 202, 202, 0.08) 33.333%,
      rgba(202, 202, 202, 0.08) 66.666%,
      rgba(221, 221, 221, 0.08) 66.666%,
      rgba(221, 221, 221, 0.08) 99.999%
    ),
    radial-gradient(
      circle at 14% 98%,
      rgba(184, 184, 184, 0.08) 0%,
      rgba(184, 184, 184, 0.08) 33.333%,
      rgba(96, 96, 96, 0.08) 33.333%,
      rgba(96, 96, 96, 0.08) 66.666%,
      rgba(7, 7, 7, 0.08) 66.666%,
      rgba(7, 7, 7, 0.08) 99.999%
    ),
    linear-gradient(45deg, rgb(97, 14, 117), rgb(20, 32, 127));
}

.styled_background__.white{
  background-image: radial-gradient(
    circle at 39% 47%,
    rgba(255, 255, 255, 0.08) 0%,
    rgba(255, 255, 255, 0.08) 33.333%,
    rgba(230, 230, 230, 0.08) 33.333%,
    rgba(230, 230, 230, 0.08) 66.666%,
    rgba(200, 200, 200, 0.08) 66.666%,
    rgba(200, 200, 200, 0.08) 99.999%
  ),
  radial-gradient(
    circle at 53% 74%,
    rgba(245, 245, 245, 0.08) 0%,
    rgba(245, 245, 245, 0.08) 33.333%,
    rgba(220, 220, 220, 0.08) 33.333%,
    rgba(220, 220, 220, 0.08) 66.666%,
    rgba(200, 200, 200, 0.08) 66.666%,
    rgba(200, 200, 200, 0.08) 99.999%
  ),
  radial-gradient(
    circle at 14% 98%,
    rgba(240, 240, 240, 0.08) 0%,
    rgba(240, 240, 240, 0.08) 33.333%,
    rgba(215, 215, 215, 0.08) 33.333%,
    rgba(215, 215, 215, 0.08) 66.666%,
    rgba(190, 190, 190, 0.08) 66.666%,
    rgba(190, 190, 190, 0.08) 99.999%
  ),
  linear-gradient(45deg, rgb(255, 255, 255), rgb(230, 230, 230));


}

.waves {
  position: relative;
  width: 100%;
  height: 150vh;
  margin-bottom: -7px; /*Fix for safari gap*/
  min-height: 100px;
  max-height: 250px;
}

/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}
