.teacher_info_bio {
  padding-block: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  display: flex;
  align-items: center;
  margin-top: 100px;
}

.teacher_info_bio .teacher_info_bio_name {
  font-size: 30px;
  font-weight: 500;
}

.teacher_info_bio .teacher_info_bio_bio {
  font-size: 20px;
  text-align: center;
  width: 800px;
  padding: 20px;
}

:root {
  --angle: 45deg;
  --opacity: 0.5;
}

.rainbow {
  padding: 2rem;
  margin: auto;


  --border-size: 0.1rem;
  border: var(--border-size) solid transparent;

  /* Paint an image in the border */
  border-image: conic-gradient(
      from var(--angle),
      #d53e33 0deg 90deg,
      #fbb300 90deg 180deg,
      #377af5 180deg 270deg,
      #60023d 270deg 360deg
    )
    1 stretch;
  /* background: rgb(255 255 255 / var(--opacity)); */
}

@property --opacity {
  syntax: "<number>";
  initial-value: 0.5;
  inherits: false;
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

@keyframes opacityChange {
  to {
    --opacity: 1;
  }
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

.rainbow {
  animation: rotate 2s linear infinite, opacityChange 3s infinite alternate;
}

/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
  /* Your styles here */
  .teacher_info_bio {
    padding-block: 20px;

    margin-top: 60px;
  }

  .teacher_info_bio .teacher_info_bio_name {
    font-size: 30px;
    font-weight: 500;
    font-size: 25px;
  }

  .teacher_info_bio .teacher_info_bio_bio {
    font-size: 17px;
    text-align: center;
    width: 90%;
    font-size: 17px;
  }
}

/* Small devices (tablets, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  /* Your styles here */
  .teacher_info_bio .teacher_info_bio_bio {
    font-size: 20px;
    text-align: center;
    width: 90%;
    font-size: 17px;
  }
}

/* Medium devices (desktops, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  /* Your styles here */
  .teacher_info_bio .teacher_info_bio_bio {
    font-size: 20px;
    text-align: center;
    width: 90%;
    font-size: 17px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  /* Your styles here */
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
  /* Your styles here */
}

/* Extra extra large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  /* Your styles here */
}
