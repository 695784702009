.icon_button__ {
  width: 34px;
  height: 34px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent !important;
  position: relative;
  /* overflow: hidden; */
  border-radius: 7px;
  z-index: 2;
  cursor: pointer;
  transition: all 0.3s;
}

.svgContainer_icon {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 2;
  justify-content: center;
  background-color: transparent;
  backdrop-filter: blur(0px);
  letter-spacing: 0.8px;
  font-size: 20px;
  color: #FFF;
  border-radius: 10px;
  transition: all 0.3s;
  border: 1px solid rgba(156, 156, 156, 0.466);
}

.BG_icon {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #181818;
  z-index: 1;
  border-radius: 10px;
  pointer-events: none;
  transition: all 0.3s;
}

.icon_button__:hover .BG_icon {
  transform: rotate(35deg);
  transform-origin: bottom;
}

.icon_button__:hover .svgContainer_icon {
  background-color: rgba(156, 156, 156, 0.466);
  backdrop-filter: blur(4px);
}
