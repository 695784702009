.side_nav_container {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 10000000;
  z-index: 10000000000;
  height: 100%;
  transition: 0.3s ease;
  transform: translateX(100%);
  /* position: relative; */
  display: none;
}
.close_side_icon{
  position: absolute;
  top: 20px;
  right: 20px;
}


.side_nav_container .overlay{
  display: none;
}
.side_nav_container.open .overlay{
  display: block;
}

.side_nav_container.open{
  transition: .3s ease;
  transform: translateX(0px);

}

.side_nav_container .side_nav_contnet {
  width: 250px;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100vh;
  background-color: #ffffff;
  overflow: auto;
  padding: 70px 10px 0;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  background-image: radial-gradient(
      circle at 39% 47%,
      rgba(107, 107, 107, 0.08) 0%,
      rgba(107, 107, 107, 0.08) 33.333%,
      rgba(72, 72, 72, 0.08) 33.333%,
      rgba(72, 72, 72, 0.08) 66.666%,
      rgba(36, 36, 36, 0.08) 66.666%,
      rgba(36, 36, 36, 0.08) 99.999%
    ),
    radial-gradient(
      circle at 53% 74%,
      rgba(182, 182, 182, 0.08) 0%,
      rgba(182, 182, 182, 0.08) 33.333%,
      rgba(202, 202, 202, 0.08) 33.333%,
      rgba(202, 202, 202, 0.08) 66.666%,
      rgba(221, 221, 221, 0.08) 66.666%,
      rgba(221, 221, 221, 0.08) 99.999%
    ),
    radial-gradient(
      circle at 14% 98%,
      rgba(184, 184, 184, 0.08) 0%,
      rgba(184, 184, 184, 0.08) 33.333%,
      rgba(96, 96, 96, 0.08) 33.333%,
      rgba(96, 96, 96, 0.08) 66.666%,
      rgba(7, 7, 7, 0.08) 66.666%,
      rgba(7, 7, 7, 0.08) 99.999%
    ),
    linear-gradient(45deg, rgb(97, 14, 117), rgb(20, 32, 127));
}

 .side_nav_contnet.open {
  box-shadow: 0 0 10px #5c5c5c;
  position: relative;
  transition: all 0.3s ease;


}

.side_nav_container.open .side_nav_contnet .side_back_btn{
  position: absolute;
  top: 35px;
  right: 20px;
  padding: 6px;
  background-color: #e3e3e3;
  border-radius: 50%;
}

.side_nav_container.open .overlay {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: #2222224d;
  width: 100vw;
  height: 100vh;
}

.side_nav_container .side_items {
  display: flex;
  flex-direction: column;
  padding-block: 10px;
  width: 100%;
  gap: 5px;
}

.side_nav_container .side_items .side_item {
  font-size: 15px;
  padding-block: 6px;
  color: #FFF;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: .2s ease;
  padding-inline: 8px;
}

.side_nav_container .side_items .side_item:hover {
  background-color: rgba(0, 0, 0, 0.31);
  border-radius: 12px;
}

.image_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.side_logo_image {
  width: 100px;
  margin-top: 30px;
  margin-inline: auto;
}

.side_logo_image img {
  width: 100%;
}
.side_nave_close {
  font-size: 17px;
  position: absolute;
  color: #5c5c5c;
  right: -100px;
  background-color: #222;
  color: #fff;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: none;
}

.side_nav_container.open .side_nave_close {
  display: block;
}

.side_accordion {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #efefef;
  padding-right: 10px;
  border-radius: 5px;
}

.bg-none {
  background-color: transparent;
}

.side_accordion .icon {
  color: #5c5c5c;
  font-size: 10px;
  transition: 0.3s ease;
}

.side_accordion .icon.open {
  transform: rotate(180deg);
}

.side_accordion_items {
  height: 0;
  transition: 0.3s ease;
  overflow: hidden;
  padding-inline: 15px;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.side_accordion_items.open {
  height: 110px;
}


.left_side_step{
  
}


.close_side_icon .menu_switch span{
  background-color: #FFF !important;
}

.side_logo{
  width: 80%;
  margin-bottom: 20px;
}

.side_logo img{
  width: 100%;
}

  
/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
  /* Your styles here */
  .side_nav_container{
    display: block;
  }
}

/* Small devices (tablets, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  /* Your styles here */
  
  .side_nav_container{
    display: block;
  }
}

/* Medium devices (desktops, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  /* Your styles here */
  .side_nav_container{
    display: block;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  /* Your styles here */
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
  /* Your styles here */
}

/* Extra extra large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  /* Your styles here */
}
