body {
  margin: 0;
  font-family: "Tajawal", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  direction: rtl;
}



.pointer {
  cursor: pointer;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

