.edu_for_everyone {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-block: 30px;
}

.edu_for_everyone .efe_card {
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  padding-block: 40px;
  gap: 10px;
  border-radius: 12px;
  transition: 0.2s ease;
}

.edu_for_everyone .efe_card .efe_card_icon {
  color: var(--vio-1);
  font-size: 50px;
  width: 60px;
}
.edu_for_everyone .efe_card .efe_card_icon img{
  width: 100%;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
}

.edu_for_everyone .efe_card .efe_card_title {
  font-size: 25px;
  color: var(--vio-1);
  font-weight: bolder;
}

.edu_for_everyone .efe_card .efe_card_desc {
  font-size: 17px;
  font-weight: 500;
  color: #5d5d5d;
}



/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
  /* Your styles here */
  .edu_for_everyone {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    margin-block: 20px;
  }

  .edu_for_everyone .efe_card {
    background-color: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    padding-block: 10px;
    gap: 0px;

  }
}

/* Small devices (tablets, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  /* Your styles here */

  .edu_for_everyone {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Medium devices (desktops, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  /* Your styles here */
  .edu_for_everyone {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  /* Your styles here */
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
  /* Your styles here */
}

/* Extra extra large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  /* Your styles here */
}
