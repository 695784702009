


.vids_content{
  display: grid;
  grid-template-columns: repeat(4 , 1fr);
  gap: 20px;
}


/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
  /* Your styles here */
  
  .vids_content{
    grid-template-columns: repeat(1 , 1fr);
  }
  
}

/* Small devices (tablets, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  /* Your styles here */
  
  .vids_content{
    grid-template-columns: repeat(1 , 1fr);
  }
  
}

/* Medium devices (desktops, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  /* Your styles here */
  
  .vids_content{
    grid-template-columns: repeat(2 , 1fr);
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  /* Your styles here */
  .vids_content{
    grid-template-columns: repeat(3 , 1fr);
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
  /* Your styles here */
  .vids_content{
    grid-template-columns: repeat(3 , 1fr);
  }
}

/* Extra extra large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  /* Your styles here */
}
