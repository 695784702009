:root{
  
  /* for colors */
 --vio-1:  #552b7a;
 --vio-2:#714c8f;
 --vio-3:#8e6ea5;
 --vio-4:#aa90bb;
 --vio-5:#c6b4d2;
 --vio-6:#e2d9e8;

 /* for global styles */
 --wave-height:250px;
  

}








.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.toastCon{
  z-index: 999999999999 !important;
}

.hover_scale:hover{
  transform: scale(1.1);
  transition: .3s;
}