

.time_line_container{
  display: inline-flex;
  flex-direction: column;
  gap: 15px;
  position: sticky;
  top: 150px;
}


.time_line_container .year{
  padding: 5px;
  padding-inline: 20px;
  font-size: 25px;
  border-radius: 10px;
  font-weight: bolder;
  transition: .2s ease;
  color: #510b3b;
  cursor: pointer;
  opacity: .35;
}




.time_line_container .year.active{
  color: #FFF;
  opacity: 1;
  background-color: var(--hello,  #7D2AE8);
  transition: .2s ease;
}

.time_line_container .year:not(.active):hover{
  transform: scale(1.1);
  opacity: 1;
}



  
/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
  /* Your styles here */
  .time_line_container{
    display: inline-flex;
    flex-direction: row;
    gap: 10px;
    max-width: calc(100vw - 30px);
    overflow-x: auto;
    overflow-y: hidden;
  }

  .time_line_container .year{
    padding: 5px;
    padding-inline:15px;
    font-size: 20px;

  }
  
  
}

/* Small devices (tablets, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  /* Your styles here */
  

}

/* Medium devices (desktops, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  /* Your styles here */

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  /* Your styles here */
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
  /* Your styles here */
}

/* Extra extra large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  /* Your styles here */
}
